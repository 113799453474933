import { createReducer } from "@reduxjs/toolkit";
import {
  setModel,
  addRoute,
  setBack,
  setHelp,
  clearRoute,
  deleteRoute,
  atualizeDelivery,
  setAnotherModel,
} from "./actions";

const delivery = createReducer(
  { route: [], price: 0 },
  {
    [setModel]: (state, action) => ({ ...state, model: action.payload }),
    [addRoute]: (state, action) => {
      state.route.push(action.payload);
    },
    [deleteRoute]: (state, action) => {
      state.route.splice(action.payload, 1);
    },
    [setBack]: (state, action) => {
      state.come_back = action.payload;
    },
    [setHelp]: (state, action) => {
      state.with_help = action.payload;
    },
    [clearRoute]: (state, _) => ({ ...state, route: [], price: 0 }),
    [atualizeDelivery]: (state, action) => ({ ...state, ...action.payload }),
    [setAnotherModel]: (state, action) => ({
      ...state,
      another_model: action.payload,
    }),
  }
);

export default delivery;
