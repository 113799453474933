const hoursParams = { hour: "2-digit", minute: "2-digit", hour12: false };
const dateParams = { day: "2-digit", month: "2-digit", year: "2-digit" };

export const epochConvert = (dateEpoch) => {
  if (!dateEpoch) {
    return undefined;
  }

  const date = new Date(dateEpoch);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year} - ${date.toLocaleTimeString(
    "pt-BR",
    hoursParams
  )}`;
};

export const convertDate = (date, hour) => {
  if (!date || !hour) {
    return undefined;
  }

  const dateStr = date.toLocaleDateString(dateParams);
  const timeStr = hour.toLocaleTimeString("pt-BR");

  const dateEpoch = new Date(dateStr + " " + timeStr).getTime("pt-BR");
  return dateEpoch;
};

export const timestampToTimezoneBR = (timestamp) => {
  if (!timestamp) {
    return undefined;
  }

  const epochBR = Date.parse(timestamp);
  const dateBR = new Date(epochBR);

  return `${dateBR.toLocaleDateString("pt-BR")} - ${dateBR.toLocaleTimeString(
    "pt-BR",
    hoursParams
  )}`;
};

export const systemIsClosed = () => {
  const now = Date.now();
  const today = new Date(now);

  const month = today.getMonth();
  const year = today.getFullYear();
  const day = today.getDate();

  let hour = today.getDay() === 0 ? 9 : 9;
  const closeHour = today.getDay() === 0 ? 23 : 23;

  const night = Date.parse(new Date(year, month, day, closeHour));

  const morning = Date.parse(new Date(year, month, day, hour));

  return now < morning || now > night;
};

export const secondsToISO = (seconds) => {
  const measuredTime = new Date(null);
  measuredTime.setSeconds(seconds);

  return measuredTime.toISOString().substr(11, 5);
};

export const dateString = (timestamp) =>
  timestamp.slice(0, 10).split("-").reverse().join("/");

// export const dateToISO = (data, hour) => {
//   const months = new Map([
//     ["01", "Jan"],
//     ["02", "Feb"],
//     ["03", "Mar"],
//     ["04", "Apr"],
//     ["05", "May"],
//     ["06", "Jun"],
//     ["07", "Jul"],
//     ["08", "Ago"],
//     ["09", "Sep"],
//     ["10", "Oct"],
//     ["11", "Nov"],
//     ["12", "Dec"]
//   ])

//   const [day, monthNumber, year] = data.split('/')
//   const epochBR = Date.parse(`${day} ${months.get(monthNumber)} ${year} ${hour}`) - 10800000

//   return epochBR
// }

export const dateToISO = (date) => {
  if (!date?.match(/\d{2}\/\d{2}\/\d{4}/) || !date) {
    return date;
  }

  const months = new Map([
    ["01", "January"],
    ["02", "February"],
    ["03", "March"],
    ["04", "April"],
    ["05", "May"],
    ["06", "June"],
    ["07", "July"],
    ["08", "August"],
    ["09", "September"],
    ["10", "October"],
    ["11", "November"],
    ["12", "December"],
  ]);

  const splitDate = date.split("/");
  const dateFormated = new Date(
    `${splitDate[0]} ${months.get(splitDate[1])} ${splitDate[2]}`
  );

  return dateFormated.toISOString();
};
