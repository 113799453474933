import { lazy, Suspense } from 'react';
import { Spinner } from "react-bootstrap";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getUser, isAuthenticated } from '../services/auth';
import AdminRoutes from './AdminRoutes';
import CompanyRoutes from './CompanyRoutes.jsx';
import EmployeeRoutes from './EmployeeRoutes.jsx';
import FinanceRoutes from './FinanceRoutes.jsx';

// const LegacyPage = lazy(() => import('../pages/common/Legacy.jsx'));
const Login = lazy(() => import('../pages/common/Login.jsx'));
const Evaluate = lazy(() => import('../pages/common/Evaluate.jsx'));

const Routes = () => {
  const user = JSON.parse(getUser());

  return (
    <BrowserRouter>
      <Switch>
        <Suspense
          fallback={
            <div className='centered height90'>
              <Spinner animation="border" variant="light" />
            </div>
          }>
          <Route
            exact path='/'
            component={
              isAuthenticated()
                ? () => <Redirect to={`/${user.role}`} />
                : Login
            }
          />
          <Route path='/login' component={Login} />
          <Route path='/evaluate/:id' component={Evaluate} />
          <FinanceRoutes />
          <AdminRoutes />
          <CompanyRoutes />
          <EmployeeRoutes />
        </Suspense>
      </Switch>
    </BrowserRouter >
  );
};

export default Routes;
// 